<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div class="q-mb-sm">
        <h6 class="title-h6 q-mt-none">{{$t('processes.linked_entity')}}</h6>
        <div class="row q-col-gutter-md q-mb-md">
          <linked-object-list :min="1" ref="entity" :linkedObjects="linkedObjects" @menuClick="onMenuClick($event)" />
          <!-- Search div -->
          <search-box v-if="searchBoxActivated" ref="searchBox"
            class="col-5 full-height"
            @input="onSelectLinkedObj"
            :label="$t('processes.fields.entity.label')"
            model_type="entity"
            :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true, 'pdv': 1 }"
            :color="color" />
          <q-btn icon="add_circle" v-else-if="linkedObjects.length < 1" :color="color" class="add-pdv-entity q-mt-md q-ml-md col-5" size="md" outline @click="searchBoxActivated = true">
            {{$t('processes.link_entity')}}
          </q-btn>
        </div>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.etatDesAides')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="justify-center">
            <div class="row q-gutter-sm">
              <base-input-select class="col" v-bind="formInputProps('type_aide_id')" ref="type_aide_id" :color="color"
                v-model="formData.type_aide_id"  />
              <base-input-select class="col" v-bind="formInputProps('millesime_id')" ref="millesime_id" :color="color"
                v-model="formData.millesime_id"  />
            </div>
            <div class="row">
              <div class="col-6">
                <base-input-text :label="$t('processes.fields.enveloppe_disponnible.label')" class="col" :color="color" :readonly="true"
                  :value="enveloppeDisponible"  />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6">{{$t('processes.montantEngage')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="justify-center">
            <div class="row q-gutter-sm">
              <base-input-text class="col" v-bind="formInputProps('montant_aide')" :color="color"
                v-model="formData.montant_aide"  />
              <base-input-date class="col" v-bind="formInputProps('date_attribution')" :color="color"
                v-model="formData.date_attribution"  />
            </div>
            <div class="row q-gutter-sm">
              <base-input-text :readonly="true" :label="$t('processes.fields.montant_regle.label')" class="col" :color="color"
                  :value="montantRegle"  />
              <base-input-text :readonly="true" :label="$t('processes.fields.reste_a_regler.label')" class="col" :color="color"
                  :value="resteARegler"  />
            </div>
            <div class="row">
              <div class="col-6">
                <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.commentaires.label')}}</q-item-label>
                <base-input-text class="q-px-sm q-pb-none row" placeholder="" label="" v-bind="formInputProps('commentaire')" :color="color"
                  v-model="formData.commentaire"  />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6">{{$t('processes.reglements')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <div class="row">
            <div class="col">Date</div>
            <div class="col">Description</div>
            <div class="col">Montant</div>
          </div>
          <div v-for="reglement in relatedReglements" :key="reglement.id" class="row col-12 items-end">
            <base-input-text class="col q-pb-none" label="" :readonly="true" :color="color"
              :value="$formatDate(reglement.date_reglement)" />
            <base-input-text class="col q-pb-none" label="" :readonly="true" placeholder="Description" :color="color"
              :value="`${reglement.description}`" />
            <base-input-text class="col q-pb-none" label="" :readonly="true" placeholder="montant" :color="color"
              :value="`${reglement.montant}`" />
          </div>
          <div class="row col-12 items-end">
            <div class="fix-padding q-pb-none col q-field--with-bottom">
              <base-input-date label="" placeholder="Date" :color="color"
                v-model="reglement.date_reglement" />
            </div>
            <base-input-text class="col q-pb-none" label="" placeholder="Description" :color="color"
              v-model="reglement.description" />
            <base-input-text class="col q-pb-none" label="" placeholder="Montant" :color="color"
              v-model="reglement.montant" />
          </div>
          <div class="row justify-end">
            <div class="col-3 q-py-sm">
              <q-btn class="full-width justify-between" color="process" size="sm" :disabled="!isUpdate" @click="addReglement" icon-right="add_circle">{{$t('processes.enregistrer')}}</q-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="bloc-item">
        <h6>{{$t('processes.links')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="row q-gutter-md collapsible-div">
          <div v-if="relatedLinks.length" class="column col col-6 q-py-md q-col-gutter-md">
            <div class="row q-gutter-sm" v-for="relatedLink in relatedLinks" :key="relatedLink.id">
              <base-card :showSeparator="false" @menuClick="onMenuClick" class="col-12" :actions="relatedLink.actions">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="link" />
                <q-item-label class="q-mb-md"><strong>{{relatedLink.titre}}</strong></q-item-label>
                <a :href="relatedLink.lien" target="_blank"><q-item-label :class="`text-${color}`">{{relatedLink.lien}}</q-item-label></a>
              </base-card>
            </div>
          </div>

          <div v-if="!isLinksFormOpen" class="col-6" style="height:81px">
            <base-add-button :label="$t('sujets.add_link')" :color="color" @click="isLinksFormOpen = true" />
          </div>

          <div v-else class="col-6">
            <q-card flat bordered class="q-pa-md">
              <base-input-text v-bind="titleTemp" :color="color"
                  v-model="link.titre" ref="admin_note" />
              <base-input-text v-bind="linkTemp" :color="color"
                  v-model="link.lien" ref="admin_note" />
              <q-btn :color="color" size="sm" class="float-right" unelevated @click="addLink()">{{$t('form.ok')}}</q-btn>
              <div style="clear: both;"></div>
            </q-card>
          </div>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
      </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'
import linkedObjectList from '../shared/linkedObjectList'

export default {
  mixins: [BaseForm],
  components: { linkedObjectList },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      searchBoxActivated: false,
      isUpdate: this.$route.params.id,
      linkedObjects: [],

      relatedReglements: [],
      reglement: {},

      // For links
      relatedLinks: [],
      isLinksFormOpen: false,
      titleTemp: { label: 'Titre du lien' },
      linkTemp: { label: 'Chemin réseau' },
      link: {
        titre: '',
        lien: ''
      },

      enveloppeDisponible: null,
      montantRegle: 0,
      resteARegler: 0
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentSuiviDesAidesProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    })
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'aide'
        this.formData.related_objects = []
        if (!newVal || !this.currentSuiviDesAidesProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options }) => ({ id, options }))
            .reduce((acc, { id, options }) => ({
              ...acc, [id]: options ? options.find(({ value }) => value === this.currentSuiviDesAidesProcess[id]) : this.currentSuiviDesAidesProcess[id]
            }), {})
        }

        // Add entity in form fields
        if (!oldVal || !oldVal.some(field => field.id === 'entity')) this.formFields.push({ id: 'entity' })

        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id
          this.linkedObjects = []

          // Set entity
          let entitiesMapped = this.currentSuiviDesAidesProcess.entities.map(entity => ({
            model_type: 'entities',
            model_id: entity.id
          }))
          this.formData.related_objects.push(...entitiesMapped)

          let mappedObjectForLinkedEntities = this.currentSuiviDesAidesProcess.entities.map(entity => ({
            type: 'entity',
            model: {
              ...entity,
              actions: [
                { label: this.$t('processes.unlink_related_object'), payload: { name: 'model-detach', params: { id: entity.id, type: 'entity' } } }
              ]
            }
          }))

          // Set links
          this.relatedLinks = this.currentSuiviDesAidesProcess.liens ? JSON.parse(this.currentSuiviDesAidesProcess.liens).map(relatedLink => {
            return {
              ...relatedLink,
              actions: [
                { label: this.$t('sujets.delete_linked_link'), payload: { name: 'delete-link', params: { id: relatedLink.id } } }
              ]
            }
          }) : []

          this.linkedObjects.push(...mappedObjectForLinkedEntities)

          // Set reglements
          this.relatedReglements = this.currentSuiviDesAidesProcess.reglements

          // Set computed value
          this.setComputedMontantsValues()
        }
      },
      immediate: true
    },
    linkedObjects () {
      this.getEnveloppeDisponible()
    },

    'formData.type_aide_id' () {
      this.getEnveloppeDisponible()
    },

    'formData.millesime_id' () {
      this.getEnveloppeDisponible()
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_des_aides'), to: { name: 'suivi-des-aides-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'suivi-des-aides-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_des_aides'))

    this.$store.commit('pages/setTabs', [
      { name: 'suivi-des-aides-form', label: 'Informations', to: { name: 'suivi-des-aides-form' }, params: { id: this.$route.params.id } }
    ])
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'aide' }).then(() => {
        store.dispatch('processes/fetchFormFields', 'aide')
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'aide')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    getEnveloppeDisponible () {
      if (this.formData.type_aide_id && this.formData.millesime_id && this.linkedObjects && this.linkedObjects.length) {
        this.$store.dispatch('processes/getEnveloppeDisponibleAide', { entity_id: this.linkedObjects[0].model.id, type_aide_id: this.formData.type_aide_id.value, millesime_id: this.formData.millesime_id.value }).then((data) => {
          this.enveloppeDisponible = data.disponible
        })
      }
    },
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    onSelectLinkedObj (object) {
      if (!object.value) return

      let getModel_typeFromSearch = (type) => type === 'entity' ? 'entities' : type
      let mappedObjectForFormData = {
        model_type: getModel_typeFromSearch(object.type),
        model_id: object.value.value
      }

      let mappedObjectForLinkedObjects = {
        type: object.type,
        model: {
          label: object.value.label,
          id: object.value.value,
          actions: [
            { label: this.$t('processes.unlink_related_object'), payload: { name: 'model-detach', params: { id: object.value.value, type: object.type } } }
          ]
        }
      }

      this.linkedObjects.push(mappedObjectForLinkedObjects)
      this.formData.related_objects.push(mappedObjectForFormData)

      this.searchBoxActivated = false

      this.$refs.searchBox.reset()
    },
    addLink () {
      if (this.link.titre === '' || this.link.lien === '') return
      let tempId = 0
      if (!this.relatedLinks) {
        this.relatedLinks = []
      } else if (this.relatedLinks.length) {
        tempId = Math.max.apply(Math, this.relatedLinks.map(link => link.id)) + 1
      }

      // Remove " first before adding
      let copiedObject = Object.assign({}, this.link)
      copiedObject.id = tempId
      copiedObject.lien = copiedObject.lien.replace(/"/g, '')
      copiedObject.actions = [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'delete-link', params: { id: tempId } } }]

      this.relatedLinks.push(copiedObject)
      this.link.titre = ''
      this.link.lien = ''
      this.isLinksFormOpen = false
    },
    addReglement () {
      if (!this.reglement.montant || !this.reglement.date_reglement) {
        this.notifyError('processes.required_value')
        return
      }
      let params = {
        ...this.reglement,
        model_type: 'reglement',
        aides_id: this.$route.params.id
      }

      this.$store.dispatch('processes/saveProcesses', params).then(data => {
        this.relatedReglements.push({ id: data.id, ...this.reglement })
        this.reglement = {}

        this.notifySuccess('processes.reglement_added')
      })

      this.setComputedMontantsValues()
    },
    setComputedMontantsValues () {
      this.montantRegle = this.relatedReglements.reduce((accumulateur, valeurCourante) => {
        return accumulateur + parseFloat(valeurCourante.montant)
      }, 0)

      this.resteARegler = this.formData.montant_aide - this.montantRegle
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) this.$router.push({ name: 'suivi-des-aides-form', params: { id } })
    },
    onError (error) {
      if (error.response.status === 404) {
        this.notifyError(this.$t(`processes.NO_AIDE_ADMIN_FOUND`))
      } else {
        this.notifyError(this.$t(`processes.${error.response.data[0]}`))
      }
    },
    onMenuClick ({ name, params }) {
      this.hasChanged = true
      if (name === 'model-detach') {
        if (name === 'model-detach') {
          let linkedWithTypeEqualsParamsTypeIdRemoved = this.linkedObjects.filter(linkObj => linkObj.type === params.type && linkObj.model.id !== params.id)
          let linkedWithTypeDifferentsParamsType = this.linkedObjects.filter(linkObj => linkObj.type !== params.type)
          this.linkedObjects = [...linkedWithTypeEqualsParamsTypeIdRemoved, ...linkedWithTypeDifferentsParamsType]

          let paramsTypeMapped = params.type === 'entity' ? 'entities' : params.type
          let relatedObjectWithTypeEqualsParamsTypeIdRemoved = this.formData.related_objects.filter(relObj => relObj.model_type === paramsTypeMapped && relObj.model_id !== params.id)
          let relatedObjectWithTypeDifferentsParamsType = this.formData.related_objects.filter(relObj => relObj.model_type !== paramsTypeMapped)
          this.formData.related_objects = [...relatedObjectWithTypeEqualsParamsTypeIdRemoved, ...relatedObjectWithTypeDifferentsParamsType]
        }
      }
    },

    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'aide', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .process-color
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0;
    top 7px;
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
  .add-pdv-entity
    height 228px
</style>
