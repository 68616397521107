<template>
  <base-card :showSeparator="showSeparator" v-bind="model" :fav_candidate="true" v-on="$listeners">
    <q-avatar slot='avatar' size="3rem" color="reseau" text-color="white" :icon="icon" />

    <template slot='default'>
      <q-item-label class="q-mb-md"><strong>{{model.label}}</strong></q-item-label>
    </template>
  </base-card>
</template>

<script>
export default {
  name: 'EntityCard',
  props: {
    'model': { type: Object, required: true },
    'icon': { type: String, default: 'business_center' },
    'showSeparator': { type: Boolean, default: true }
  }
}
</script>

<style>
</style>
