<template>
  <div class="linked-object-list">
    <div class="column q-col-gutter-md">
      <div :class="`row`" v-for="(object, index) in linkedObjects" v-bind:key="object.id">
        <component class="full-width" :is="getComponent(object.type)" v-bind="object" v-on="$listeners" v-model="linkedObjects[index]" :showSeparator="false" />
      </div>
    </div>
  </div>
</template>

<script>
import { WalletCard } from '../../../components/wallets'
import { AgentCard } from '../../../components/agents'
import { EntityCard } from '../../../components/entities'

export default {
  name: 'linkedObjectList',
  components: { },
  props: {
    linkedObjects: { type: Array, default: () => ([]) },
    pdv: { type: Number },
    min: { type: Number, default: () => 0 }
  },

  computed: {

  },

  methods: {
    hasError () {
      return this.linkedObjects.length < this.min
    },
    getComponent (type) {
      if (type === 'wallet') {
        return WalletCard
      } else if (type === 'agent') {
        return AgentCard
      } else if (type === 'entity') {
        return EntityCard
      }
      return null
    }
  }
}
</script>

<style>
</style>
